<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.endType" :options="$store.state.enumAll.offerTypeEnum"
            placeholder="报价类型" @change="setOfferType" style="width:140px" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader style="width:200px" v-model:value="searchForm.start" :options="$store.state.app.cityDict"
            :changeOnSelect="true" @change="setStartAddress" :show-search="{ filter }" placeholder="选择起始地" />

        </a-form-item>
        <a-form-item label="">
          <a-cascader style="width:200px" v-model:value="searchForm.end" :options="$store.state.app.cityDict"
            :changeOnSelect="true" @change="setEndAddress" :show-search="{ filter }" placeholder="选择目的地" />
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
          <!--          <a-button @click="exportAll">-->
          <!--            导出全部-->
          <!--          </a-button>-->
        </a-space>
      </a-form>
    </template>

    <a-table size="small" :columns=columns :data-source="listData" :row-key="record => record.id" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <!--      <template #lineName="{record}">-->
      <!--        <div>-->
      <!--          <a @click="detailShow=true,carrierId=record.id,carrierData=record">{{ record.lineName }}</a>-->
      <!--        </div>-->
      <!--      </template>-->
    </a-table>
  </HxTable>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'

import HxTable from '@/components/HxTable'
import { page } from '@/api/carrier/optimalLineExternal'
import { message } from 'ant-design-vue';

export default {
  components: {
    HxTable
  },
  setup () {
    const state = reactive({
      loading: false,
      listData: [],
      searchForm: {
        lineName: '',
        charge: '',
        chargeMobile: ''
      },
      columns: [
        {
          title: '线路名称',
          // width: '10%',
          align: 'center',
          dataIndex: 'startEndCity',
        },
        {
          title: '线路详情',
          dataIndex: 'lineName',
          align: 'center'
          // width: '10%',
        },
        {
          title: '承运方',
          dataIndex: 'carrierName',
          align: 'center'
        },
        {
          title: '途经点',
          dataIndex: 'passPoint',
          align: 'center'
        },
        {
          title: '承运商报价',
          dataIndex: 'finalPrice',
          align: 'center'
        },

        {
          title: '时效',
          dataIndex: 'prescription',
          align: 'center',
          width: '5%'
        },
        {
          title: '历史承运次数',
          dataIndex: 'num',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '对外报价',
          dataIndex: 'finalPrice',
          align: 'center'
        },
        {
          title: '报价类型',
          dataIndex: 'endType.label',
          align: 'center'
        },
        {
          title: '提示语',
          dataIndex: 'hint',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })

    const setOfferType = (e, v) => {
      state.searchForm.offerType = v
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        startProvinceId: state.searchForm.startProvinceId,
        startCityId: state.searchForm.startCityId,
        startAreaId: state.searchForm.startAreaId,
        endProvinceId: state.searchForm.endProvinceId,
        endCityId: state.searchForm.endCityId,
        endAreaId: state.searchForm.endAreaId,
        endType: state.searchForm.endType,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    // 导出全部
    const exportAll = () => {
      message.error("研发中")
    }

    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvinceId = value[0]
      if (value.length > 1) {
        state.searchForm.startCityId = value[1]
      } else {
        state.searchForm.startCityId = null
      }
      if (value.length > 2) {
        state.searchForm.startAreaId = value[2]
      } else {
        state.searchForm.startAreaId = null
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endProvinceId = value[0]
      if (value.length > 1) {
        state.searchForm.endCityId = value[1]
      } else {
        state.searchForm.endCityId = null
      }
      if (value.length > 2) {
        state.searchForm.endAreaId = value[2]
      } else {
        state.searchForm.endAreaId = null
      }
    }
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      setOfferType,
      handleTableChange,
      exportAll,
      setStartAddress,
      setEndAddress
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
